import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Core components
import GlobalStyle from "./GlobalStyle";
import PrivateRoute from "./services/routeGuadrd";
import styled from "styled-components";

// Lazy loaded components
const ScanAgain = React.lazy(() => import("./pages/ScanAgain"));
const Locations = React.lazy(() => import("./pages/Locations"));
const Menu = React.lazy(() => import("./pages/Menu"));
const MenuItem = React.lazy(() => import("./pages/menu-items"));
const CartPage = React.lazy(() => import("./pages/Cart"));
const Login = React.lazy(() => import("./pages/Login"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Payment = React.lazy(() => import("./pages/Payment"));
const VerifyOTP = React.lazy(() => import("./pages/verifyOtp"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const OrderSuccess = React.lazy(() => import("./pages/OrderSuccess"));
const Orders = React.lazy(() => import("./pages/Orders"));
const TimeoutPage = React.lazy(() => import("./pages/TimeoutPage"));
const OrderFailedPage = React.lazy(() => import("./pages/OrderFailed"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const Loader = styled.div`
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// Browser message component
const BrowserMessage: React.FC = () => (
  <div style={{ textAlign: "center", padding: "50px" } as React.CSSProperties}>
    <h1>Mobile Only</h1>
    <p>
      This site is optimized for mobile devices. Please access it on your mobile
      phone.
    </p>
  </div>
);

const App: React.FC = () => {
  // useEffect(() => {
  //   const checkTokenExpiration = () => {
  //     const accessToken = Cookies.get("accessToken");
  //     const refreshToken = Cookies.get("refreshToken");

  //     if (!accessToken || !refreshToken) {
  //       // Clear local storage and session storage if tokens are not present
  //       localStorage.clear();
  //       sessionStorage.clear();
  //       Cookies.remove("accessToken");
  //       Cookies.remove("refreshToken");
  //     }
  //   };

  //   checkTokenExpiration();
  // }, []);

  return (
    <>
      <GlobalStyle />
      <BrowserView>
        <BrowserMessage />
      </BrowserView>

      <MobileView>
        <Router>
          <Suspense
            fallback={
              <div
                style={
                  {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  } as React.CSSProperties
                }
              >
                <Loader />
              </div>
            }
          >
            <ToastContainer />
            <Routes>
              {/* Public routes */}
              <Route path="" element={<ScanAgain />} />
              <Route path="/" element={<ScanAgain />} />
              <Route path="/:locationId?" element={<Locations />} />
              <Route path="/locations/:locationId" element={<Locations />} />
              <Route path="/menu/:locationId" element={<Menu />} />
              <Route path="/menu-items/:locationId" element={<MenuItem />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify" element={<VerifyOTP />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/timeout" element={<TimeoutPage />} />
              <Route path="/order-fail" element={<OrderFailedPage />} />
              <Route path="/reset_password" element={<ResetPassword />} />

              {/* Protected routes */}
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/payment"
                element={
                  <PrivateRoute>
                    <Payment />
                  </PrivateRoute>
                }
              />

              {/* Catch-all route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </MobileView>
    </>
  );
};

export default App;
